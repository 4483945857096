import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Footer from '../components/Footer6/Footer';
import Headers from '../components/Header102/Header';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          companyName
        }
      }
    }
  `);

  // サイトのメタデータから会社名を抽出
  const companyName = data.site.siteMetadata.companyName;

  return (
    <div>
      <Headers
        logoImage="/ver2/siesta-logo.jpg"
        title={companyName}
        items={[
          {
            id: 2,
            name: 'ABOUT US',
            dropdown: false,
            link: '/about',
          },
          {
            id: 3,
            name: 'PRODUCT',
            dropdown: false,
            link: '/product',
          },
          {
            id: 4,
            name: '採用情報',
            dropdown: false,
            link: '/recruit',
          },
          {
            id: 5,
            name: 'SHOP',
            dropdown: false,
            link: '/shop',
          },
          {
            id: 6,
            name: 'CONTACT',
            dropdown: false,
            link: '/contact',
          },
        ]}
      />
      {children}
      <Footer
        items={[
          {
            categoriesname: 'シェスタ株式会社',
            categories: [
              { name: 'ABOUT', link: '/about' },
              { name: 'PRODUCT', link: '/product' },
              { name: '採用情報', link: '/recruit' },
              { name: 'SHOP', link: '/shop' },
              { name: 'コラム一覧', link: '/pages' },
              { name: 'CONTACT', link: '/contact' },
              { name: 'プライバシーポリシー', link: '/privacy' },
            ],
          },
          {
            categoriesname: 'リヴェラール',
            categories: [
              { name: '【公式】リヴェラール', link: 'https://riverall.jp/' },
              {
                name: '【楽天市場】リヴェラール',
                link: 'https://www.rakuten.co.jp/riverall/',
              },
              {
                name: '【yahooショッピング】リヴェラール',
                link: 'https://shopping.geocities.jp/riverall/',
              },
              {
                name: '【Amazon】リヴェラール',
                link: 'https://www.amazon.co.jp/s?me=A3154ROTTLSWKG&marketplaceID=A1VC38T7YXB528',
              },
              {
                name: '【au PAY マーケット】リヴェラール',
                link: 'https://plus.wowma.jp/user/11386081/plus/index.html',
              },
              {
                name: '【Qoo10】リヴェラール',
                link: 'https://www.qoo10.jp/shop/riverall?__ar=Y',
              },
              {
                name: '【BUYMA】リヴェラール',
                link: 'https://www.buyma.com/buyer/4108056.html',
              },
              {
                name: '【MEGASEEK】リヴェラール',
                link: 'https://www.magaseek.com/shop/top/sh_RIVERA-tr_s',
              },
            ],
          },
          {
            categoriesname: 'ショップ',
            categories: [
              {
                name: 'リンレ楽天市場店',
                link: 'https://www.rakuten.co.jp/fascino/',
              },
              {
                name: '【楽天市場】marcadimoda',
                link: 'https://www.rakuten.co.jp/marcadimoda/',
              },
              {
                name: '【yahooショッピング】Fascino',
                link: 'https://shopping.geocities.jp/riverall-men/',
              },
            ],
          },
          {
            categoriesname: 'おすすめの財布',
            categories: [
              {
                name: 'フローレンスシリーズ',
                link: 'https://item.rakuten.co.jp/riverall/rinre-stndrzlw/',
              },
              {
                name: 'バッファローレザーシリーズ',
                link: 'https://item.rakuten.co.jp/riverall/217900/?variantId=53280',
              },
            ],
          },
        ]}
        logo="/ver2/siesta-logo.jpg"
        logoAltText="シェスタ株式会社"
        copyrightText="シェスタ株式会社"
        logoLinkText="/"
        instagramUrl="https://www.instagram.com/riverall_official/"
      />
    </div>
  );
};

export default Layout;
