// src/pages/Product.js
import * as React from 'react';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import Layout from '../components/layout';
import Seo from '../components/seo';

const ProductPage = ({ data }) => {
  return (
    <Layout>
      <FeatureSectionLeft
        imageSrc="/p101.jpeg"
        imageAlt="Example image"
        title="RINRE フローレンスシリーズ"
        subTitle="Florence"
        description={`ハイブランドも多数使用している有名なタンナー『MASINI社』の貴重な"カーフレザー"を使用したお財布。

        ステンドグラスのような綺麗な色合いはイタリアの熟練した職人が1色1色丁寧に手作業にて色付けしています。

        メーカー希望小売価格
        19,800円
        `}
        sectionId="section1"
        ctrText="詳しくはこちら"
        ctrLink="https://item.rakuten.co.jp/riverall/rinre-stndrzlw/"
      />
      <FeatureSectionRight
        imageSrc="/p104.webp"
        imageAlt="Example image"
        title="RINRE バッファローレザーシリーズ"
        subTitle="BUFFALO"
        description={`
        水牛本革ならではのツヤが放つ高級感はスーツとの相性抜群！
        シンプルなデザインなのに驚異的な収納力。
        バックに忍ばせておくと大変便利で、一度使うと手放せなくなること間違いなし！
      `}
        sectionId="section1"
        ctrText="詳しくはこちら"
        ctrLink="https://search.rakuten.co.jp/search/mall/%E3%83%AA%E3%83%B3%E3%83%AC%E3%80%80%E3%83%90%E3%83%83%E3%83%95%E3%82%A1%E3%83%AD%E3%83%BC%E3%83%AC%E3%82%B6%E3%83%BC/?sid=227318"
      />
      <FeatureSectionLeft
        imageSrc="/p107.jpeg"
        imageAlt="Example image"
        title="RINRE ラウンドファスナー長財布"
        subTitle="fastener"
        description={`
        職人の手によって一つ一つ丁寧に作られた高級感あふれるレディーズ長財布。
        スムーズなファスナー開閉で使いやすさ抜群！
      `}
        sectionId="section1"
        ctrText="詳しくはこちら"
        ctrLink="https://item.rakuten.co.jp/riverall/riv203/"
      />
    </Layout>
  );
};

export default ProductPage;

export const Head = () => (
  <Seo
    title="PRODUCT"
    description="PRODUCT - Florence シリーズ （フローレンスシリーズ）"
  />
);
